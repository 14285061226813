<template>
  <v-container>
    <v-row>
      <v-col lg="6" class="mx-auto">
        <v-card
          elevation="4" 
          class="mx-auto pt-4" 
          justify="center"
        >
          <v-btn
            to="/portfolio/"
            plain
          >
            <v-icon
              dark
              left
            >
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
          <v-card-text>
            <h1>{{ item.title }}</h1>
            <p v-if="item.year">{{ item.year }}</p>
            <v-row>
              <v-col
                v-for="(img, imgIdx) in item.images"
                v-bind:key="imgIdx"
                :sm="img.flex"
                class="mx-auto"
              >
                <v-card class="pa-2">
                  <v-img
                    :src="require(`@/assets/images/${img.url}`)"
                    :alt="img.alt"
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-sheet
              v-if="item.tech"
              color="white"
              elevation="1"
              class="pa-4 my-2"
            >
              <strong>Technologies:</strong> {{ item.tech }}
            </v-sheet>
              <v-sheet
                color="white"
                elevation="1"
                class="pa-4 my-2"
              >
                <strong>Description:</strong>
                <p
                  v-for="(para, paraIdx) in item.desc"
                  v-bind:key="paraIdx"
                  class="body-1"
                >
                  {{ para }}
                </p>
              </v-sheet>
            <v-sheet
              v-if="item.resources"
              color="white"
              elevation="1"
              class="pa-4 mt-2"
            >
              <strong>Resources:</strong> 
              <v-row class="pt-2">
                <v-col
                  v-for="(link, linkIdx) in item.resources"
                    v-bind:key="linkIdx"
                >
                  <v-btn
                    :href="link.link"
                    target="_blank"
                    color="blue lighten-3"
                  >
                    {{ link.name }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {
  data () {
    return {
      item: {}
    }
  },
  computed: {
    portfolioImage () {
      const fileName = 'item.title';
      return require(`../assets/images/${fileName}`)
    }
  },
  created() {
    this.item = this.$store.getters.getPortfolioItem(this.$route.params.index)

  },
  // mounted () {
  //   // const item = this.portfolio[this.$route.params.index]
  //   // this.item = item
  // }
}
</script>

<style lang="css" scoped>
</style>
